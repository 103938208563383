/* Styles for action icons */
body {
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

/* Styles for table */
table {
    td {
        vertical-align: middle !important;
    }
}

/* Styles for card body */
.card-body {
    padding: 0.5rem;
}
